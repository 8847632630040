<template>
  <div class="atropos" ref="atroposRef">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <q-btn
            color="primary"
            dense
            icon="close"
            :size="`${$q.screen.lt.md ? 'md' : '18px'}`"
            square
            unelevated
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, nextTick, onMounted, ref } from "vue";

defineOptions({ name: "SectionClose" });

const atropos = inject("atropos");
const atroposRef = ref(null);

onMounted(async () => {
  await nextTick();

  const myAtropos = atropos({
    el: atroposRef.value,
  });
});
</script>
