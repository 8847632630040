<template>
  <q-page-sticky
    position="bottom-right"
    :offset="$q.screen.lt.md ? [16, 16] : [48, 48]"
    @click="onClick()"
  >
    <div class="atropos cursor-pointer" ref="atroposRef">
      <div class="atropos-scale">
        <div class="atropos-rotate">
          <div class="atropos-inner">
            <div class="bg-primary cursor-pointer q-pt-sm q-px-sm">
              <q-img src="~/assets/cq.svg" />
            </div>
            <q-btn
              color="primary"
              dense
              :icon="drawer ? 'close' : 'menu'"
              :size="`${$q.screen.lt.md ? 'md' : '18px'}`"
              square
              unelevated
            />
          </div>
        </div>
      </div>
    </div>
  </q-page-sticky>
</template>

<script setup>
import { inject, nextTick, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";

defineOptions({ name: "DrawerNav" });

const appStore = useAppStore();
const { drawer } = storeToRefs(appStore);
const atropos = inject("atropos");
const atroposRef = ref(null);

const onClick = () => {
  drawer.value = !drawer.value;
};

onMounted(async () => {
  await nextTick();
  const myAtropos = atropos({
    el: atroposRef.value,
  });
});
</script>
