<template>
  <div v-intersection="options" :class="[contentClass]">
    <div class="max-ch-80 q-mx-auto">
      <portable-text :components="bodyComponent" :value="data.body" />
    </div>
  </div>
</template>

<script setup>
import { computed, h, ref } from "vue";
import { storeToRefs } from "pinia";
import { Dialog, useQuasar } from "quasar";
import { PortableText } from "@portabletext/vue";
import { useAppStore } from "src/stores/app";
import DialogLetterComponent from "src/components/dialog/DialogLetterComponent.vue";

defineOptions({ name: "BodyComponent" });

const props = defineProps({
  data: { type: Object, default: () => {} },
});

const $q = useQuasar();
const appStore = useAppStore();
const { contentClass } = storeToRefs(appStore);
const blockquoteClass = ref("q-py-md text-caption text-grey-8");
const textClass = computed(() => ($q.screen.lt.md ? "text-h6" : "text-h5"));
const linkClasses = "custom-link q-pt-none";
const percent = ref(0);
const thresholds = [];
for (let i = 0; i <= 1.0; i += 0.01) {
  thresholds.push(i);
}
const options = {
  handler(entry) {
    const val = (entry.intersectionRatio * 100).toFixed(0);
    if (percent.value !== val) {
      percent.value = val;
    }
  },
  cfg: {
    threshold: thresholds,
  },
};

const handleClick = (value) => {
  Dialog.create({
    component: DialogLetterComponent,
    componentProps: {
      type: "letter",
      data: value,
    },
  });
};

const bodyComponent = {
  marks: {
    link: ({ value }, { slots }) => {
      const target = value?.url?.startsWith("http") ? "_blank" : undefined;
      if (value.banner?.length) {
        return h(
          "span",
          {
            class: linkClasses,
            onClick: () => {
              handleClick(value);
            },
          },
          [slots.default?.()]
        );
      } else if (value.url) {
        return h(
          "a",
          {
            href: value.url,
            target,
            class: linkClasses,
            rel: target === "_blank" ? "noopener" : undefined,
          },
          [slots.default?.()]
        );
      }
    },
  },
  block: {
    h5: (_, { slots }) => {
      return h("h4", { class: textClass.value }, slots.default?.());
    },
    blockquote: (_, { slots }) => {
      return h(
        "blockquote",
        { class: blockquoteClass.value },
        slots.default?.()
      );
    },
  },
};
</script>
