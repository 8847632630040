<template>
  <q-page class="column full-width">
    <q-scroll-area
      class="full-width mask-overflow-b"
      ref="scrollArea"
      :style="pageStyle"
      :thumb-style="thumbStyle"
      visible
    >
      <div id="top" />
      <template v-for="section in app.page.sections" :key="section._key">
        <div v-if="section.intro" class="column items-center justify-center">
          <div
            v-if="section.label"
            :class="[
              $q.screen.lt.md ? 'q-pl-md' : 'q-pl-xl',
              `items-start ${$q.screen.lt.md ? 'q-py-lg' : 'q-py-xl'}`,
            ]"
            class="full-width justify-between row z-top"
            :style="{
              top: 0,
            }"
          >
            <mark
              :class="[$q.screen.lt.md ? 'text-h6' : 'text-h5']"
              class="bg-primary q-px-sm text-white"
              >{{ section.label }}</mark
            >
          </div>
          <div :class="contentClass">
            <transition
              appear
              enter-active-class="animated fadeIn slower"
              leave-active-class="animated fadeOut"
            >
              <body-component
                v-if="splashClicked"
                class="q-pb-xl q-px-xl q-mx-auto"
                :data="{ body: section.intro }"
              />
            </transition>
          </div>
        </div>
        <section-row
          :active="slug === section.slug.current"
          :height="height"
          :length="app.page.sections.length"
          :section="section"
          style="min-height: 113px"
          :width="pageSize.width"
        />
      </template>
      <div class="row items-center justify-center q-pb-md">
        <div :class="contentClass">
          <body-component class="q-mx-auto" :data="{ body: app.page.outro }" />
        </div>
      </div>
      <div class="full-width justify-center q-mb-xl q-mt-md row">
        <cv-button />
      </div>
    </q-scroll-area>
    <drawer-nav />
    <splash-screen v-if="!splashClicked" />
    <q-resize-observer @resize="(size) => onResize(size)" />
  </q-page>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { colors, scroll, useMeta } from "quasar";
import { urlFor } from "src/boot/sanityUrlFor";
import { useEventListener } from "@vueuse/core";
import { useAppStore } from "src/stores/app";
import { useProjectsStore } from "src/stores/projects";
import BodyComponent from "src/components/body/BodyComponent.vue";
import CvButton from "src/components/cv/CvButton.vue";
import DrawerNav from "src/components/drawer/DrawerNav.vue";
import SectionRow from "src/components/section/SectionRow.vue";
import SplashScreen from "src/components/splash/SplashScreen.vue";

defineOptions({ name: "IndexPage" });

const { getPaletteColor } = colors;
const { getScrollTarget, setVerticalScrollPosition } = scroll;
const router = useRouter();
const appStore = useAppStore();
const { app, closeSection, contentClass, splashClicked } =
  storeToRefs(appStore);
const projectsStore = useProjectsStore();
const { nextProject, prevProject, slug } = storeToRefs(projectsStore);
const isClient = ref(false);
const pageHeight = ref("100dvh");
const pageSize = ref({ width: 0, height: 0 });
const pageStyle = computed(() => {
  return { height: "100dvh" };
});
const scrollArea = ref(null);
const slugParam = computed(
  () => router.currentRoute.value.params.section || null
);
const currentSection = computed(() =>
  app.value.page.sections.find(
    (section) => section.slug.current === slugParam.value
  )
);
const height = computed(() => pageSize.value.height / 8);
const metaCardBackup = "/card.jpg";
const metaDescription = ref(
  "I am a multi-disciplinary storyteller that lifts people and brands as a Producer, Writer, Director, Editor, Experience Designer, UI/UX Designer, Full-Stack Developer, Educator, and Brand Designer."
);
const metaImage = computed(() => {
  let image = metaCardBackup;
  if (currentSection.value) {
    const imageUrl =
      currentSection.value.project.media[0].image?.asset?.url || null;
    if (imageUrl) {
      image = urlFor(imageUrl).width(1200).height(630).url();
    }
  }
  return image;
});

const metaTitle = computed(() => {
  let title = "Chris Quezada";
  if (currentSection.value) {
    if (currentSection.value.title === title) {
      title += " / About";
    } else {
      title += ` / ${currentSection.value.title}`;
    }
  }
  return title;
});
const thumbColor = ref("#fff");
const thumbStyle = computed(() => {
  return {
    borderRadius: "0px",
    backgroundColor: thumbColor.value,
    opacity: 0.6,
    zIndex: 999,
  };
});

const onResize = (size) => {
  pageSize.value = size;
};

const scrollToElement = (id) => {
  if (!isClient.value) return;
  const el = document.getElementById(id);
  if (el) {
    const target = getScrollTarget(el);
    const offset = el.offsetTop - 32;
    const duration = 400;
    setVerticalScrollPosition(target, offset, duration);
  }
};

const setScrollContainerStyle = () => {
  if (!isClient.value) return;
  const container = scrollArea.value?.$el?.querySelector(
    ".q-scrollarea__container"
  );
  if (container) {
    container.style.minHeight = pageHeight.value;
    container.style.scrollSnapType = "y mandatory";
  }
};

const toPrevProject = () => {
  router.push({
    name: "section",
    params: {
      project: prevProject.value.slug.current,
    },
  });
};

const toNextProject = () => {
  router.push({
    name: "section",
    params: {
      project: nextProject.value.slug.current,
    },
  });
};

onMounted(async () => {
  isClient.value = true;
  useEventListener(document, "keydown", (e) => {
    if (e.key !== "ArrowUp" && e.key !== "ArrowDown") return;
    e.key === "ArrowUp" ? toPrevProject() : toNextProject();
  });
  setScrollContainerStyle();
  thumbColor.value = getPaletteColor("primary");
});

useMeta(() => {
  return {
    title: metaTitle.value,
    meta: {
      description: {
        name: "description",
        content: metaDescription.value,
      },
      ogDescription: {
        property: "og:description",
        content: metaDescription.value,
      },
      ogImage: {
        property: "og:image",
        content: metaImage.value,
      },
      ogTitle: {
        property: "og:title",
        content: metaTitle.value,
      },
    },
  };
});

watch(closeSection, async (newCloseSection) => {
  await nextTick();
  scrollToElement(newCloseSection);
});

watch(pageHeight, async () => {
  await nextTick();
  setScrollContainerStyle();
});

watch(
  slugParam,
  async (newSlugParam) => {
    slug.value = newSlugParam;
    await nextTick();
    scrollToElement(slug.value);
  },
  { immediate: true }
);

watch(splashClicked, async () => {
  await nextTick();
  scrollToElement("top");
});
</script>
